import { HeadProps } from "gatsby";
import React from "react";
import BasicPage from "../components/BasicPage";
import Layout from "../components/Layout";
import Seo from "../features/seo/Seo";

const faqs: { question: string; answer: string }[] = [
  {
    question: "How do I cancel my subscription?",
    answer:
      'Click "Cancel automatic billing" on the subscription section of your account settings page.',
  },
  {
    question: "What is your refund policy?",
    answer: `We can issue a refund within 90 days after your payment date. Just <a href="mailto:learn@giancolianswers.com">contact me</a> to make your request, and be sure to include the email address used to make the purchase so that I can look up the account.`,
  },
  {
    question: "Is this website and my credit card information secure?",
    answer: `Definitely! We partner with Paddle and Stripe for payments processing. Your payment information is sent directly from your browser to Paddle or Stripe and never reaches the Giancoli Answers website servers.`,
  },
  {
    question: `Can you also answer the "General Problems"?`,
    answer: `It would be enormously time consuming to also answer all the "General Problems" so I'm limiting coverage only to the regular "Problems", of which there are still more than 1700. If you need help with a "General Problem", my suggestion would be to try and find a regular "Problem" that is similar to the "General Problem", and see if you can apply the same problem solving technique.`,
  },
  {
    question: `Will you answer the question I left below a video?`,
    answer: `It depends. I certainly want to, but I need to be time efficient since it would be enormously time consuming to give personal email help to all subscribers. However, often I do respond to comments on the site, although sometimes I leave them for other students to pick up. Here are the things that I'm thinking about when deciding whether I'll be able to get to your question:
<ul class="list-disc list-inside my-2">
<li>Is the question also useful for other students?</li>
<li>Is there a specific time mentioned for the video? (example: "at 2:10, you mentioned a = b^2, so...") Having a specific time to look at helps me know right away exactly what you're asking about.</li>
<li>How much time do I have? (I always wish I had more!)</li>
</ul>
If you're pointing out an error in a solution, I always respond and thank you for mentioning that.`,
  },
];

const Faq = () => {
  return (
    <Layout>
      <div className="bg-white rounded-xl mt-8">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-neutral-900">
                Frequently asked questions
              </h2>
              <p className="mt-4 text-lg text-neutral-500">
                Can’t find the answer you’re looking for? Reach out to{" "}
                <a
                  href="mailto:learn@giancolianswers.com"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  learn@giancolianswers.com
                </a>
                .
              </p>
            </div>
            <div className="mt-12 lg:col-span-2 lg:mt-0">
              <dl className="space-y-12">
                {faqs.map((faq, index) => (
                  <div key={index}>
                    <dt className="text-lg font-medium leading-6 text-neutral-900">
                      {faq.question}
                    </dt>
                    <dd
                      className="mt-2 text-base text-neutral-500"
                      dangerouslySetInnerHTML={{
                        __html: faq.answer,
                      }}
                    />
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Frequently asked questions | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
